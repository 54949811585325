.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media (min-height: 800px) {
	.wrapper {
		height: 100vh;
	}
}


.Redeem {
	display: flex;
	flex-direction: column;
	/* width: 100%; */
	width: 400px;
	/* padding: 20px; */
	background-color: #f0f0f0;
	align-items: center;
	/*overflow: hidden;*/
}

.scratch::-webkit-scrollbar {
	display: none;
}

.Redeem .scratch {
	overflow: scroll;
	/* display: flex;
	flex-direction: column;
	align-items: center;
	width: 300px;
	height: 600px;
	border-radius: 10px;
	overflow: hidden;
	position: relative; */
	position: relative;
}

/* .Redeem .message {
	width: 300px;
	overflow: hidden;
	position: relative;
	margin-top: 10px;
} */

.Redeem .scratchcard {
	inset: 0;
	display: grid;
	place-items: center;
	position: absolute;
	/* width: 80%; */
	top: 63%;
	left: 49.5%;
	transform: translate(-50%, -50%);
	/* border: 1px solid #000; */
	padding: 0;
}

.Redeem canvas {
	position: absolute;
	cursor: grabbing;
	/* border: 1px solid #000; */
	/* width: 100%; */
	touch-action: none;
}

.Redeem .scratchImg {
	/* max-width: 100%; */
	/* max-height: 100%; */
	width: 100%;
	height: auto;
}

.Redeem .heading {
	text-align: center;
}

.Redeem .heading img {
	width: 100px;
	height: auto;
}

.success {
	color: green;
}

.error {
	color: red;
}

.message {
    position: fixed;
    top: 0;
    width: 400px;
    text-align: center;
}

@media (max-width: 400px) {
	.message {
		width: 100%;
	}
}

@media (min-height: 800px) {
	.message {
		position: absolute;
	}
}


.disclaimer {
	text-align: center;
	font-size: 14px;
	color: #aaa;
}

.noSelect {
	user-select: none;
	/* standard syntax */
	-webkit-user-select: none;
	/* Safari 3.1+ */
	-moz-user-select: none;
	/* Firefox 2+ */
	-ms-user-select: none;
	/* IE 10+ */
}

/* 
.message {
	transition: opacity 300ms ease-in-out;
}

.message-enter {
	opacity: 0;
}

.message-enter-active {
	opacity: 1;
}

.message-exit {
	opacity: 1;
}

.message-exit-active {
	opacity: 0;
} */