.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-height: 800px) {
    .wrapper {
        height: 100vh;
    }
}

.register {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.content {
    padding: 5px 5px;
}

.register form {
    display: flex;
    flex-direction: column;
}

.register button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}

.register .heading {
    text-align: center;

    h3 {
        color: #242424;
        /* font-size: 17px; */
        margin-top: 2px; /* reduce space before h3 */
    }

    h4 {
        font-size: 15px;
        color: #5e5e5e;
        margin-bottom: 5px; /* reduce space after h5 */
    }

    h5 {
        color: #858585;
        font-size: 13px;
        margin-top: 5px; /* reduce space before h4 */
        margin-bottom: 5px; /* reduce space after h4 */
    }
}

.register img {
    width: 100%;
    /* max-width: 400px; */
    /* height: auto; */
}

@media (max-width: 600px) {
    .register {
        width: 90%;
    }
}

.success {
    color: green;
}

.error {
    color: red;
}

.message {
    text-align: center;
}

.disclaimer {
    text-align: center;
    font-size: 14px;
    color: #aaa;
}

.captcha {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.gdprConsent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.gdprConsent input[type="checkbox"] {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
}

.gdprConsent label {
    font-size: 0.9em;
    color: #333;
}
