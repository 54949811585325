.confidentialite {
	margin: 0 auto;
	padding: 20px;
	width: 80%;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	height: 100%;
}

.center {
	display: flex;
	flex-direction: column;
	align-items: center;
}